$primaries: (
  "blue": theme('colors.primary.blue'),
  "orange": theme('colors.primary.orange'),
  "gray": theme('colors.primary.gray'),
);

// * MIXINS *
@mixin custom-flex-row {
  @apply
    flex-row
    flex
    justify-between
    content-center;
}

@mixin custom-flex-item {
  @apply
    flex;

    * {
      @apply
        self-center;
    }
}

@mixin center-content {
  @apply
    flex
    items-center
    justify-center;
}

@mixin no-focus {
  @apply
    shadow-none
    outline-none;

    &:focus {
      @apply
        shadow-none
        outline-none;
    }
}

@mixin button {
  @apply
    rounded-full
    py-1
    px-4
    text-center
    uppercase
    text-sm
    border;

  letter-spacing: .05em;
}

.btn {
  @include button;
  @include no-focus;

  @apply
    basic-transition;

  &:hover {
    @apply
      shadow-md;

    &.border-none {
      @apply
        underline;
    }
  }
}

.btn-outline {
  @extend .btn;
}

.flex-wrapper {
  @screen sm {
    @apply
      flex
      flex-wrap;
  }
}

@each $name, $hexa in $primaries {
  .btn-#{$name} {
    background-color: #{$hexa};
    border-color: #{$hexa};

    &:hover {
      @apply
        opacity-75;
    }
  }

  .btn-outline-#{$name} {
    border-color: #{$hexa};
    color: #{$hexa};

    &:hover {
      background-color: #{$hexa};

      @apply
        text-primary-blue;
    }
  }
}

@mixin goTo {
  @apply
    tracking-0.5
    text-gray
    text-xxs
    uppercase
    font-thin
    basic-transition;

    &:hover {
      @apply
        text-lblue;
    }
}